import React from 'react';
import tlogo from "../assets/resultCropped.png"

const TermsAndConditions = () => {
  return (

    <div className="terms-container">
          <header>
          <div className='logoHeader'>< img src={tlogo} alt="Logo" width={60} height={60} />
      <span>thinkercan</span>
        </div>
      </header>
                    <div className="terms-content">

    <h1 className='h-conds'>Thinkercan's Terms and Conditions</h1>
      
      <p className='p-conds'>
        Welcome to Thinkercan! These terms and conditions outline the rules and regulations for the use of Thinkercan's website and services, located at <a href="https://thinkercan.com/">https://thinkercan.com/</a> (the "Service"). By accessing or using the Service, you agree to comply with and be bound by these terms. If you disagree with any part of these terms, you must not use the Service.
      </p>

      <h2 className='h-conds'>1. Business Information</h2>
      <p className='p-conds'>
        Thinkercan is a service provider offering AI-powered math-solving solutions. Users can generate text responses to their math questions using our service.
      </p>

      <h2 className='h-conds'>2. User Information and Accounts</h2>
      <p className='p-conds'>
        <strong>Account Creation:</strong> To use our Service, users must create an account. We collect only the information necessary for authentication.
        <br />
        <strong>Age Restriction:</strong> Users must be at least 13 years old to use the Service.
      </p>

      <h2 className='h-conds'>3. Intellectual Property Rights</h2>
      <p className='p-conds'>
        Thinkercan acknowledges and respects the intellectual property rights of all individuals and entities, and expects all users of the Service to do the same. Any attempts to reverse engineer, copy, reproduce, or otherwise steal intellectual property from the Service will not be tolerated and may result in legal action.
      </p>

      <h2 className='h-conds'>4. Purchases and Payments</h2>
      <p className='p-conds'>
        <strong>Subscriptions:</strong> We offer monthly and yearly subscriptions for our Service.
        <br />
        <strong>Payment Methods:</strong> We accept credit card payments.
        <br />
        <strong>Refunds:</strong> Users seeking refunds must contact our support team at <a href="mailto:support@thinkercan.com">support@thinkercan.com</a>.
      </p>

      <h2 className='h-conds'>5. Privacy and Data Protection</h2>
      <p className='p-conds'>
        We are committed to protecting your privacy. Please refer to our Privacy Policy for detailed information on how we handle your data.
      </p>

      <h2 className='h-conds'>6. User Conduct</h2>
      <p className='p-conds'>
        By using the Service, you agree to the following:
        <ul>
          <li className='p-conds'>You will not conduct any systematic or automated data collection activities (including scraping, data mining, data extraction, or data harvesting) on or in relation to the Service.</li>
          <li className='p-conds'>Prohibited data collection includes, but is not limited to, using the Service as input into other services, websites, or databases.</li>
          <li className='p-conds'>You will not republish any content generated by the Service without clearly citing the Service and its context. Misrepresentation of the source or nature of the content is strictly prohibited.</li>
        </ul>
      </p>

      <h2 className='h-conds'>7. Disclaimers and Liability</h2>
      <p className='p-conds'>
        <strong>Limitation of Liability:</strong> In no event shall Thinkercan, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
        <br />
        <strong>Disclaimer of Warranties:</strong> Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
        <br />
        Thinkercan, its subsidiaries, affiliates, and its licensors do not warrant that (a) the Service will function uninterrupted, secure, or available at any particular time or location; (b) any errors or defects will be corrected; (c) the Service is free of viruses or other harmful components; or (d) the results of using the Service will meet your requirements.
      </p>

      <h2 className='h-conds'>8. Termination and Suspension</h2>
      <p className='p-conds'>
        We reserve the right to terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.
      </p>

      <h2 className='h-conds'>9. Contact Information</h2>
      <p className='p-conds'>
        If you have any questions about these terms, please contact us at <a href="mailto:support@thinkercan.com">support@thinkercan.com</a>.
      </p>
    </div>
    </div>
  );
};

export default TermsAndConditions;
