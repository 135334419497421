import React, { useState } from 'react';
import { Modal, Button, ToggleButton, Card, Row, Col, Container } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import CheckoutButton from './checkoutButton';


const SubscriptionModal = ({ show, handleClose, uid }) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState('monthly');
  const monthlyPrice = 10;
  const annualPrice = 96; // 25% discount


  return (
    <Modal scrollable={true} className="subs_modal" show={show} onHide={handleClose} centered>
      <Modal.Header className="modal_subscribe_header">
      <Modal.Title className="w-100 text-center" style={{color:"#e8eaec", fontSize:"2rem"}}>Subscribe to Thinkercan</Modal.Title>

      <div style={{display:"flex", flexDirection:"row"}}>
      <div className='auth-close' onClick={handleClose} ><MdClose size={24}/></div></div>
      </Modal.Header>

      <Modal.Body>

        <div className="d-flex justify-content-center mb-3">

          <div className='toggle_button_group'

          >
            <ToggleButton
            className='toggle_btn'

              name="subscription"
              value="monthly"
              checked={subscriptionPlan === 'monthly'}
              onClick={() => setSubscriptionPlan('monthly')}
              style={{
                backgroundColor:subscriptionPlan === 'monthly' ? '#007bff' : '#d5dce2',
                color:subscriptionPlan === 'monthly' ? 'white' : '#007bff'

              }}
            >
              Monthly
            </ToggleButton>
            <ToggleButton
                        className='toggle_btn'

              name="subscription"
              value="annually"
              checked={subscriptionPlan === 'annually'}
              onClick={() => setSubscriptionPlan('annually')}
              style={{
                backgroundColor:subscriptionPlan === 'annually' ? '#007bff' : '#d5dce2',
                color:subscriptionPlan === 'annually' ? 'white' : '#007bff'

              }}
            >
              Annually
            </ToggleButton>
          </div>
        </div>
        <Card className="price_card">
            <Card.Body>
                <Card.Title  style={{paddingBottom:'1rem'}}>{subscriptionPlan === 'monthly' ? 'Monthly Plan' : 'Annual Plan (Save %20)'}</Card.Title>
                <Card.Text>
                    <span className="price-value">
                        {subscriptionPlan === 'monthly' ? `$${monthlyPrice}` : `$${annualPrice}`}
                    </span>
                    <span className="price-duration">
                        {subscriptionPlan === 'monthly' ? ' / month' : ' / year'}
                    </span>
                </Card.Text>
                <CheckoutButton priceId={subscriptionPlan === 'monthly' ? process.env.REACT_APP_STRIPE_MONTHLY_KEY : process.env.REACT_APP_STRIPE_ANNUALLY_KEY} uid={uid}/>
          <Container className='mt-5'>
          <Row className='text-center'  style={{display: 'flex', justifyContent: 'center'}}>
            <Col xs={12} md={6}>
            <Card className="attr-card text-center">
              <Card.Body>
                <Card.Title style={{fontSize:"1.4rem"}}><strong>Step-by-Step Solutions</strong></Card.Title>
                <Card.Text>
                  <span style={{ fontSize: '1rem' }}>Detailed guidance for a wide range of math problems.
                  </span>
                  </Card.Text>
              </Card.Body>

            </Card>
          </Col>
          <Col xs={12} md={6}>
          <Card className="attr-card text-center">
              <Card.Body>
                <Card.Title style={{fontSize:"1.4rem"}}><strong>CAS-Based Solver Agents</strong></Card.Title>
                <Card.Text>
                  <span style={{ fontSize: '1rem' }}> Advanced Computer Algebra Systems for accurate solutions.
                  </span>
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
          <Card className="attr-card text-center">
              <Card.Body>
                <Card.Title style={{fontSize:"1.4rem"}} ><strong>More Precise Answers</strong></Card.Title>
                <Card.Text>
                  <span style={{ fontSize: '1rem' }}>  Overcome the limitations of LLMs in math with our specialized agents.
                  </span>
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
          <Card className="attr-card text-center">
              <Card.Body>
                <Card.Title style={{fontSize:"1.4rem"}}><strong>Multi-Functionality</strong></Card.Title>
                <Card.Text>
                  <span style={{ fontSize: '1rem' }}>Solve equations, graph functions, and more.
                  </span>
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={7}>
          <Card className="attr-card text-center">
              <Card.Body>
                <Card.Title style={{fontSize:"1.4rem"}}><strong>Unlimited with Subscription</strong></Card.Title>
                <Card.Text>
                  <span style={{ fontSize: '1.1rem' }}>Access extensive problem-solving capabilities.
                  </span>
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          </Row>
          </Container>
                        </Card.Body>
                    </Card>

      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;



