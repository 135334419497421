import Sidebar from '../components/sideBar';
import BottomBar from '../components/bottomBar';


const NavBarMenu = ({isMobile, subscriptionShow, updateSubscriptionState, modalShow, updateModalState, updateAuthState, isLogged, user, userAvatar, resetCredits, uid, isSubscribed, handleAnonymousSignIn}) => {

  return (
isMobile ?
 <BottomBar subscriptionShow={subscriptionShow} updateSubscriptionState={updateSubscriptionState} modalShow={modalShow} updateModalState={updateModalState} isLogged={isLogged} user={user} userAvatar={userAvatar} updateAuthState={updateAuthState} uid={uid} isSubscribed={isSubscribed} handleAnonymousSignIn={handleAnonymousSignIn}> </BottomBar> :
 <Sidebar modalShow={modalShow} updateModalState={updateModalState} subscriptionShow={subscriptionShow} updateSubscriptionState={updateSubscriptionState} isLogged={isLogged} user={user} userAvatar={userAvatar} updateAuthState={updateAuthState}  resetCredits={resetCredits} uid={uid} isSubscribed={isSubscribed} handleAnonymousSignIn={handleAnonymousSignIn}></Sidebar>

  );
};

export default NavBarMenu;