import React from 'react';
import tlogo from "../assets/resultCropped.png"

function Privacy() {
    return (
        <div className="terms-container">
            <header>
          <div className='logoHeader'>< img src={tlogo} alt="Logo" width={60} height={60} />
      <span>thinkercan</span>
        </div>
      </header>
            <div className="terms-content">
            <h1  className='h-conds'>Thinkercan's Privacy Policy</h1>
            <p className='p-conds'>Last updated: May 17, 2024</p>
            <p className='p-conds'>
              This Privacy Policy describes Our policies and procedures on the collection, use and
              disclosure of Your information when You use the Service and tells You about Your
              privacy rights and how the law protects You.
            </p>
            <p className='p-conds'>
              We use Your Personal data to provide and improve the Service. By using the Service,
              You agree to the collection and use of information in accordance with this Privacy
              Policy.
            </p>
            <h2 className='h-conds'>Our services</h2>
            <p className='p-conds'>
              Thinkercan provides a text generation service. Thinkercan is available via   <a href="https://thinkercan.com/" target="_blank" rel="noopener noreferrer">
                    https://thinkercan.com/
                  </a> .
            </p>
            <h2 className='h-conds'>Information We Collect</h2>
            <p className='p-conds'>When you visit our website, we may collect certain information automatically from your device. This information may include your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location), and other technical information. We may also collect information about how your device has interacted with our website, including the pages accessed and links clicked. Collecting this information enables us to better understand the visitors who come to our website, where they come from, and what content is of interest to them. We use this information for our internal analytics purposes and to improve the quality and relevance of our service.</p>
            <p className='p-conds'>If you create an account on our website, we will collect certain information from you, including your name, email address, and password. We use this information to provide you with access to your account and to provide you with customer support.</p>

            <h2 className='h-conds'>Information We Do Not Collect</h2>
            <p className='p-conds'>
            We do not knowingly collect any sensitive information from you, such as information about your race or ethnic origin, political opinions, religion, or health.
            </p>

            <h2 className='h-conds'>Sharing of Information</h2>
            <p className='p-conds'>We do not sell, trade, or otherwise share your personal information with third parties, except as described in this Policy. We may share your personal information with the following types of third parties:</p>

            <ul>
              <li className='p-conds'>Service providers. We may share personal information with service providers that perform certain services on our behalf. For example, we may use service providers to send email or other communications, process payments, or provide customer support.</li>
              <li className='p-conds'>Analytics service providers. We may share anonymized usage information with analytics service providers that assist us in the improvement and optimization of our website. For example, Google Analytics:  <a href="https://policies.google.com/technologies/partner-sites." target="_blank" rel="noopener noreferrer">
              https://policies.google.com/technologies/partner-sites.
                  </a> .</li>
          
            </ul>
       
            <h2 className='h-conds'>Third Party Services</h2>
            <p className='p-conds'>
            We may use third party services, such as Application Programming Interfaces (APIs), to maintain and improve our services. For example, queries may transmitted to OpenAI API in order to serve requests. No personally identifiable information (PII) is transmitted unless required for continuity of service, for example sending email, processing payments, or providing customer support.
            </p>           

            <p className='p-conds'>We use both Session and Persistent Cookies for the purposes set out below:</p>
             
             
            <h2 className='h-conds'>Security</h2>
            <p className='p-conds'>We take reasonable measures to protect the personal information that we collect from you. Data is stored securely on Firebase. However, no security measure is perfect, and we cannot guarantee the security of your personal information.</p>
            
            <p className='p-conds'>We may share Your personal information in the following situations:</p>
   
            <h2 className='h-conds'>Data Retention</h2>
            <p className='p-conds'>
            We retain your personal information for as long as your account is active or as needed to provide you with our Services. You may opt out of data collection for AI data collection which would allows us to use your search data to improve our AI models in your settings page if you are logged into our platform. 
            </p>
            <p className='p-conds'>
            If you delete your account, we will delete your personal information from our servers within 30 days. Please contact us at <a class="framer-text framer-styles-preset-1qdinzp" href="mailto:support%40thinkercan.com" rel="noopener">support@thinkercan.com</a> to request deletion.
            </p>
            <h2 className='h-conds'>Changes to our Privacy Policy</h2>
            <p className='p-conds'>
            We may change this Policy from time to time. If we make changes, we will post the revised Policy on our website and update the “Last updated” date above. We encourage you to review the Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the choices available to you.
            </p>
            <h2 className='h-conds'>Contact Us</h2>
            <p className='p-conds'>
            If you have any questions about this Policy, please contact us at <a class="framer-text framer-styles-preset-1qdinzp" href="mailto:support%40thinkercan.com" rel="noopener">support@thinkercan.com</a>


            </p>
        
         

            </div>
        </div>
    );
}

export default Privacy;