import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { Spinner } from 'react-bootstrap';

function Question({questInput}) {
  const ImageWithSpinner = ({ src, alt, isFinished }) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          filter: isFinished ? 'none' : 'blur(0.3rem)',
          transition: 'filter 0.3s ease-in-out',
        }}
      />
      {!isFinished && (
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
        </Spinner>
      )}
      </div>

    );
  };
  
    return (
      <div className="quest">
      { questInput.quest_image  ? <ImageWithSpinner src={questInput.quest_image} isFinished={questInput.ocr_finished}/> : <Latex>{questInput.quest_text.replace(/\\\\/g,"\\").replace(/\n/g,"<br>")}</Latex>
}
    </div>
    );
  }

  export default Question;
