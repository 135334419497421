// Account.js
import React, {useState, useEffect} from 'react';
import { auth } from '../firebase/firebaseAuth';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { Modal, Button, Form, Carousel, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CancelSubscriptionButton from '../components/cancelSubscription';
import { ref, onValue } from "firebase/database";
import { db } from '../firebase/realtimeDatabase';

const Account = ({resetCredits, handleAnonymousSignIn}) => {
  // Mock data for the current user's profile

  const [uid, setUid] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [username, setUserName] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);

  const [email, setEmail] = useState(null);
  const navigate = useNavigate();


  const handleLogout = async () => {
    try {
      if (auth.currentUser) {
        if (auth.currentUser.email !== "anonymous"){
            await auth.signOut();
            localStorage.removeItem('authToken');
            resetCredits(0);
            await handleAnonymousSignIn();
            navigate('/');


    // Redirect to home page
            //setLogState(false);
        }

      } else {
        console.log('No user is currently signed in');
      }
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  const currentUser = {
    username: 'JohnDoe',
    email: 'john.doe@example.com',
    subscriptionType: 'none',
    currentCredits: '0',
  };
  useEffect(() => {
    const unSubscribeAuth = onAuthStateChanged(auth,
        async authenticatedUser => {
         if (authenticatedUser){
            setUid(authenticatedUser.uid);
            setEmail(authenticatedUser.email);
            setUserName(authenticatedUser.providerData[0]?.displayName);
            const userRef = ref(db, `/users/${authenticatedUser.uid}/userInfo`);
            onValue(userRef, (snapshot) => {
              if (snapshot.exists()){

                const data = snapshot.val();
                if (data){
                  setIsSubscribed(data.subscribed)
                  if (data.subscribed){
                    setIsCancelled(data.subscriptionCancelled)
                  }

              }
              }
            });


         }

         else{
            setEmail(currentUser.email);
            setUserName(currentUser.username);
            setIsSubscribed(false)


         }
        })
        return unSubscribeAuth;


  }, []);


  return (

    <div className='main mt-5'>
        <Container className='account'>
            <Row className='text-center'>
        <h2 className="account-title">Profile</h2>
        </Row>
        <Row className='profile-detail'>
            <Col>  <span>Email</span> </Col>
            <Col> <span>{email}</span> </Col>
        </Row>
        <Row className='profile-detail'>
            <Col>  <span>Subscription</span> </Col>
            <Col> <span>{currentUser.subscriptionType}</span> </Col>
        </Row>


        <div className='mt-5 text-end'>
              <Button variant="outline-info" size='lg' onClick={handleLogout}>
                Log out
              </Button>
              </div>

        {isSubscribed && !isCancelled && <div className='mt-2 text-end'>
          <CancelSubscriptionButton uid={uid}/>
        </div>}
        {isSubscribed && isCancelled && <div className='mt-2 text-end'>
        <Button variant="danger" size='lg' disabled >
                Cancelled
              </Button>
        </div>}


      </Container>
    </div>
  );
};

export default Account;


