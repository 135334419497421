import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import {Button} from 'react-bootstrap';


const CheckoutButton = ({ priceId, uid }) => {
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleCheckout = async () => {
    setIsSubscribing(true);
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

    const stripe = await stripePromise;
    const createCheckoutSession = firebase.functions().httpsCallable('createCheckoutSession');
    const { data } = await createCheckoutSession({ priceId: priceId, uid: uid });
    const result = await stripe.redirectToCheckout({ sessionId: data.id });

    if (result.error) {

      console.error(result.error.message);
  }
  };

  return (
    <Button variant='primary' className='subscribe-btn' size="lg" onClick={handleCheckout} disabled={isSubscribing}>Subscribe Now</Button>

  );
};

export default CheckoutButton;

