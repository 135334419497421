import React, { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

const LatexRenderer = ({ content }) => {

  const lines = content.trim().replaceAll(/\[\n/g,"\[" ).replaceAll(/\n\s*\\]/g,"\\]").
  replace(/\\\[/g,"\\[\\textcolor{#A1A1FF}{").
  replaceAll("\\]"," }\\]").
  replaceAll("\\(","\\(\\textcolor{#A1A1FF}{").
  replaceAll("\\)","}\\)").
  split('\n');


  return (
    <div>
      {lines.map((line, index) => (
            <div key={index} className='answer_text'>
            {line.trim().startsWith("###") ? <h1 style={{fontSize: "1.7rem", lineHeight: "3.8rem", fontWeight: 'bold', marginTop: '2rem'}}><Latex>{line.replaceAll(/###/g, "")}</Latex> </h1> : <div style={{marginBottom:"1rem"}}><Latex>{line}</Latex></div>}
        </div>
      ))}
    </div>
  );
};

export default LatexRenderer;
