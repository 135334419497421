import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
    const navigate = useNavigate();

  const handleBackToApp = () => {
    navigate('/');
};

  return (
    <div className="page-container cancel-page">
      <button className="back-button" onClick={handleBackToApp}>← Back to App</button>
      <div className="content">
        <h1>Subscription Failed</h1>
        <p>Unfortunately, the payment was not successful, and your subscription could not be activated.</p>
        <p>Please try again or contact our support team for assistance. We’re here to help you get started.</p>
      </div>
    </div>
  );
};

export default CancelPage;
