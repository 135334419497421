import React, { useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import { IoSend, IoImage } from "react-icons/io5";
import ImageCropDialog from './ImageCropDialog';
import { Modal, Button } from 'react-bootstrap';
import { MdPhotoCamera, MdSend } from 'react-icons/md';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { MdClose } from "react-icons/md";


const AnimatedButton = ({onSend, isFinished}) => {
    const cropperRef = useRef(null);
    const [textInput, settextInput] = useState("");
    const [image, setImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const MAX_WIDTH = 720;
    const MAX_HEIGHT = 1280;

    const handleClose = () => {
      setShowModal(false);
      setImage(null); // Reset the image state
    };


    const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
    };

    const handleSend = () => {
        const sendData = {textInput:textInput, imageInput:null, croppedImage:null};
        if (cropperRef.current){
          const cropper = cropperRef.current.cropper;

          setShowModal(false);
          setImage(null);

          const croppedCanvas = cropper.getCroppedCanvas()
          cropperRef.current = null

          if (croppedCanvas) {
            const originalWidth = croppedCanvas.width;
            const originalHeight = croppedCanvas.height;

            // Calculate the scaling factor to fit within MAX_WIDTH and MAX_HEIGHT
            let scale = Math.min(MAX_WIDTH / originalWidth, MAX_HEIGHT / originalHeight);

            // Ensure we don't upscale images
            if (scale > 1) scale = 1;

            const scaledWidth = originalWidth * scale;
            const scaledHeight = originalHeight * scale;

                // Create a new canvas with the scaled dimensions
            const scaledCanvas = document.createElement("canvas");
            scaledCanvas.width = scaledWidth;
            scaledCanvas.height = scaledHeight;
            const ctx = scaledCanvas.getContext("2d");

            // Draw the image onto the scaled canvas
            ctx.drawImage(
              croppedCanvas,
              0,
              0,
              originalWidth,
              originalHeight,
              0,
              0,
              scaledWidth,
              scaledHeight
            );

            const croppedImage = scaledCanvas.toDataURL();


            const partData = croppedImage.split(',');
            // is this part true ?
            sendData.imageInput = { inlineData: { data: partData[1], mimeType: partData[0].split(';')[0].split(':')[1] } };
            sendData.croppedImage = croppedImage;
            settextInput('');
          }

        }
        onSend(sendData);

      };




    return isFinished ?(<div className="inp">
    <input
        accept="image/*"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        onChange={handleImageChange}
        key={image}
      />


    <TextField
    className="textField"
    inputProps={{ style: { color: "white", fontSize: "1.5rem"} }}
    InputProps={{ startAdornment:<label htmlFor="icon-button-file">
      <IconButton color="primary" component="span">
        <MdPhotoCamera size={22} />
      </IconButton>
    </label>

      , endAdornment: <button disabled={textInput.length === 0 ? true : false}
        className={textInput.length === 0 ? 'animated-button' : 'animated-button active'}
        onClick={() => {handleSend(); settextInput("")}}

    >
        <IoSend size={30}/>

    </button>
    , sx: { borderRadius: "3rem", boxShadow: "revert-layer" } }}

    id="outlined-multiline-static"
    multiline
    fullWidth={true}
    value={textInput}
    onChange={e => settextInput(e.target.value)}
    placeholder='Ask a question'/>
    <Modal className='crop-modal' centered size="lg" backdrop={false} show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header className="modal_subscribe_header">
      <Modal.Title className="w-100 text-center" style={{color:"#e8eaec", fontSize:"1.5rem"}}>Crop a single question</Modal.Title>

      <div style={{display:"flex", flexDirection:"row"}}>
      <div className='auth-close' onClick={handleClose} ><MdClose size={24}/></div></div>
      </Modal.Header>
        <Modal.Body>
          {image && (
            <Cropper
            src={image}
            style={{ height: '100%', width: '100%', maxHeight: '70vh' }} // Ensure it fits within the viewport height
            initialAspectRatio={1}
            guides={false}
            ref={cropperRef}
            cropBoxResizable={true}
            cropBoxMovable={true}
            dragMode="move"
            responsive={true}
            zoomable={true}
            toggleDragModeOnDblclick={false}
            checkOrientation={true}
            background={false}
            autoCropArea={1.0}
            viewMode={1}




            />
          )}
          <div className='mt-3 mb-3 text-end'>
          <Button variant="secondary" onClick={handleClose} style={{color:'white', margin:'0.5rem'}} size='lg'>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSend} style={{color:'white', margin:'0.5rem'}} size='lg'>
            Crop Image
          </Button>
          </div>
        </Modal.Body>

      </Modal>
    </div>): null;

  };

  export default AnimatedButton;