import firebaseConfig from "../firebase/firebaseConfig";
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";


import firebase from 'firebase/compat/app';

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp)
export const auth = firebase.auth(firebaseApp);
