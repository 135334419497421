import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { MdClose } from "react-icons/md";

const CancelSubscriptionButton = ({ uid }) => {
    const [showModal, setShowModal] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [error, setError] = useState(null);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleCancelSubscription = async () => {
        setIsCancelling(true);
        setError(null);
        const cancelSubscriptionFn = firebase.functions().httpsCallable('cancelSubscription');

        try {

            const result = await cancelSubscriptionFn({ uid });
            if (result.data.success) {
                handleClose();
            }
        } catch (err) {
            setError('Failed to cancel subscription. Please try again.');
            console.error('Error canceling subscription:', err);
        } finally {
            setIsCancelling(false);
        }
    };

    return (
        <>
            <Button variant="danger" onClick={handleShow} >
                Cancel Subscription
            </Button>

            <Modal className="cancel_modal" centered show={showModal} onHide={handleClose}>
                <Modal.Header>
                <Modal.Title className="w-100 text-center" style={{color:"#e8eaec", fontSize:"1.4rem"}}>Confirm Cancellation</Modal.Title>
                <div style={{display:"flex", flexDirection:"row"}}>
                <div className='auth-close' onClick={handleClose} ><MdClose size={24}/></div></div>
                </Modal.Header>
                <Modal.Body style={{color:"#e8eaec", fontSize:"1.3rem", alignItems:"center"}}>
                    Are you sure you want to cancel your subscription?
                    {error && <div className="text-danger mt-3">{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isCancelling}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleCancelSubscription} disabled={isCancelling}>
                        {isCancelling ? 'Cancelling...' : 'Yes, Cancel Subscription'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CancelSubscriptionButton;
