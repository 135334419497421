import React from 'react';
import "../contactUs.css";
import profilePic from "../assets/profilePic.jpeg"; // Add the founder's profile picture to the src folder
import logo from "../assets/resultCropped.png";
import linkedinLogo from "../assets/In-Blue-96.png";

function ContactUs() {
  return (
    <div className="Contact-container">
    <div className='logoHeader'>< img src={logo} alt="Logo" width={60} height={60} />
      <span>thinkercan</span>
        </div>
    <div className="ContactUs">
   
      <div className="ContactUs-section">
      <div className='Contact-header'><h1>CONTACT US</h1></div>

        <div className="Profile">

          <img src={profilePic} className="Profile-pic" alt="Founder" />
          <h1>Hurşit Noyan Funda</h1>

          <h2>Founder</h2>

            <a href="https://www.linkedin.com/in/noyan-funda-33093b165/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinLogo} className="linkedin-pic" alt="Founder" />

            </a>
            <p>h.noyanfunda@gmail.com</p>
        </div>
        <div className="Company-info">
          <h3>Company Address</h3>
          <p>71-75 Shelton Street<br /> Covent Garden<br />London<br /> UNITED KINGDOM<br /> WC2H 9JQ</p>
          <h3>Support Email</h3>
          <p>support@thinkercan.com</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ContactUs;
