import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();

    const handleBackToApp = () => {
      navigate('/');
  };

  return (
    <div className="page-container success-page">
      <button className="back-button" onClick={handleBackToApp}>← Back to App</button>
      <div className="content">
        <h1>Subscription Successful</h1>
        <p>Thank you for your payment! Your subscription has been activated successfully.</p>
        <p>You can now enjoy all the premium features of our service. If you have any questions or need support, please don't hesitate to reach out to us.</p>
      </div>
    </div>
  );
};

export default SuccessPage;
